import React from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";


import "./src/styles/fonts/PlainLight-Regular.css";
import "./src/styles/fonts/PlainMedium-Regular.css";
import "./src/styles/fonts/PlainThin-Regular.css";

import "./src/styles/global.css";
import FirebaseContext from "./src/context/firebase";

const firebaseConfig = {
	apiKey: process.env.GATSBY_FIREBASE_API_KEY,
	authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
	storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.GATSBY_FIREBASE_APP_ID,
	measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export const wrapRootElement = ({ element }) => (
	<FirebaseContext.Provider value={{ firebaseApp, analytics }}>
		{element}
	</FirebaseContext.Provider>
);

export const onRouteUpdate = ({ location }) => {
	if (process.env.NODE_ENV !== `production` || typeof gtag !== `function`) {
		return null;
	}

	const config = {};

	// const pathIsExcluded =
	// 	location &&
	// 	typeof window.excludeGtagPaths !== `undefined` &&
	// 	window.excludeGtagPaths.some((rx) => rx.test(location.pathname));

	// if (pathIsExcluded) return null;

	// wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
	const sendPageView = () => {
		const pagePath = location
			? location.pathname + location.search + location.hash
			: undefined;
		window.gtag(`event`, `page_view`, { page_path: pagePath });
	};

	const { delayOnRouteUpdate = 0 } = config;

	if (`requestAnimationFrame` in window) {
		requestAnimationFrame(() => {
			requestAnimationFrame(() =>
				setTimeout(sendPageView, delayOnRouteUpdate)
			);
		});
	} else {
		// Delay by 32ms to simulate 2 requestOnAnimationFrame calls
		setTimeout(sendPageView, 32 + delayOnRouteUpdate);
	}

	return null;
};