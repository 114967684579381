import { FirebaseApp } from "firebase/app";
import { Analytics } from "firebase/analytics";

import {
	createContext,
} from "react";

interface FirebaseContextData {
	firebaseApp: FirebaseApp;
	analytics: Analytics;
}

export default createContext<FirebaseContextData>(
	{} as FirebaseContextData
);
